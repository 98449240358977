import { enumValueToEnumKey } from '../../common/helpers/enumValueToEnumKey';
import { getFeatureToggles } from '../../config/appSettings';
import { DatalabScreens } from '../../features/datalab/constants';

export type DatalabAction = 'CHART' | 'TABLE' | 'DOWNLOAD';
const Chart: DatalabAction = 'CHART';
const Table: DatalabAction = 'TABLE';
const Download: DatalabAction = 'DOWNLOAD';

type MigrationMap = Partial<{ [key in DatalabScreens]: DatalabAction[] }>;

const notMigrated: MigrationMap = {
  // Reports that should only use Ruby still
  // Remove items from this list as we progress in the ruby migration
  [DatalabScreens.UNDERWRITER_PROFILE_BUY]: [Chart, Table, Download],
  [DatalabScreens.IOI_PARTICIPATION]: [Table, Download],
  [DatalabScreens.LOCK_UP_EXPIRATION]: [Table, Download],
  [DatalabScreens.UNDERWRITER_OFFERINGS]: [Table, Download],
  [DatalabScreens.AFTERMARKET_UNDERWRITER_OFFERINGS]: [Table, Download],
  [DatalabScreens.IOI_LEAGUE_TABLE]: [Table, Download],
  [DatalabScreens.PL_LEAGUE_TABLE]: [Table, Download],
  [DatalabScreens.BROKER_PL_OFFERINGS]: [Table, Download],
  [DatalabScreens.FUND_IOI_PARTICIPATION]: [Table, Download],
  [DatalabScreens.FUND_IOI_LEAGUE_TABLE]: [Table, Download],
  [DatalabScreens.CAPITAL_RAISED_MARKET]: [Chart, Download],
};

const acceptanceTesting: MigrationMap = {
  // Reports that are ready for testing with dotnet
  // Put Items here to easily turn them on in INT, remove them entirely once ready for roll-out to prod
  // Example:
  [DatalabScreens.UNDERWRITER_PROFILE_SELL]: [Chart, Table, Download],
  [DatalabScreens.FOLLOW_ON_PRICING]: [Chart],
  [DatalabScreens.IPO_BACKLOG]: [Table, Download],
  [DatalabScreens.SPONSOR_LEAGUE_TABLE]: [Table, Download],
  [DatalabScreens.AFTERMARKET_MATRIX]: [Table, Download],
  [DatalabScreens.IOI_PARTICIPATION]: [Chart],
  // [DatalabScreens.FUND_IOI_PARTICIPATION]: [Table, Download],
};

const isInMap = (map: MigrationMap, screen: DatalabScreens, action: DatalabAction): boolean =>
  !!(map[screen] && map[screen]!.includes(action));

export const isMigrated = (section: string, action: DatalabAction): boolean => {
  const screen = DatalabScreens[enumValueToEnumKey(DatalabScreens, section)];

  if (isInMap(notMigrated, screen, action)) {
    return false;
  }

  const {
    // NOTE: This feature flag is poorly named.
    enableUseRestReports: migrationAcceptanceTestingIsOn,
  } = getFeatureToggles();

  if (!migrationAcceptanceTestingIsOn && isInMap(acceptanceTesting, screen, action)) {
    return false;
  }

  return true;
};
